export const coreTraits: string[] = [
  'Empathetic',
  'Kind',
  'Open-minded',
  'Honest',
  'Assertive',
  'Good Listener',
  'Adaptable',
  'Self Motivated',
  'Big Picture Focused',
  'Data Driven',
  'Results Driven',
];

// 'Forward Thinking',
// 'Social',
// 'Quick Study',
